<template>
  <div>
    <NavBar />
    <full-text-search />
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import FullTextSearch from "../components/FullTextSearchTable";

export default {
  name: "FullText",

  components: {
    NavBar,
    FullTextSearch,
  },
};
</script>
