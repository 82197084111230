<template>
  <v-container>
    <div class="pb-10 grey--text text--darken-1 display-1">
      {{ $t("fullsearch.title") }}
    </div>

    <v-alert v-show="errorAxios" class="mb-6" type="error">
      {{ $t("meeting_table.error_connection") }}
    </v-alert>

    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.4)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="60"
            :width="7"
            color="primary"
          >
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-card>
      <v-toolbar flat>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{
              $t("meeting_table.delete")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">{{
                $t("meeting_table.cancel")
              }}</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                $t("meeting_table.ok")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <v-card-title>
        <v-row>
          <v-col cols="12" md="9">
            <v-text-field
              @keyup.enter="actualSearch()"
              v-model="search_text"
              :label="$t('fullsearch.search')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="text-center">
            <v-btn
              color="primary"
              dark
              class="ma-0 mr-md-5 ma-0 mt-md-5"
              @click="actualSearch()"
            >
              {{ $t("fullsearch.search_now") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-expansion-panels class="ma-0 mt-5">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col cols="4">
                    {{ $t("fullsearch.advanced_filter") }}
                  </v-col>
                  <v-col cols="8" class="text--secondary">
                    <v-fade-transition leave-absolute>
                      <span v-if="open" key="0"></span>
                      <span v-if="isFiltered()" key="0">
                        {{ $t("fullsearch.isNotFiltered") }}
                      </span>
                      <span v-else key="1">{{
                        $t("fullsearch.isFiltered")
                      }}</span>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="text-right">
                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectWhoProcessed"
                    :items="itemPersons"
                    :label="$t('fullsearch.filter_who_processed')"
                    multiple
                    chips
                    class="ma-0 ml-md-4"
                  ></v-combobox>
                </v-col>
                <v-spacer></v-spacer>

                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectOvm"
                    :items="itemOvm"
                    :label="$t('fullsearch.filter_ovm')"
                    multiple
                    chips
                    class="ma-0 mr-md-4"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectWhoApproved"
                    :items="itemPersons"
                    :label="$t('fullsearch.filter_who_approved')"
                    multiple
                    chips
                    class="ma-0 ml-md-4"
                  ></v-combobox>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectParticipant"
                    :items="itemPersons"
                    :label="$t('fullsearch.filter_participant')"
                    multiple
                    chips
                    class="ma-0 mr-md-4"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectVersion"
                    :items="itemVersion"
                    :label="$t('fullsearch.filter_version')"
                    multiple
                    chips
                    class="ma-0 ml-md-4"
                  ></v-combobox>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectProject"
                    :items="itemProject"
                    :label="$t('fullsearch.filter_project')"
                    chips
                    class="ma-0 mr-md-4"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectTaskType"
                    :items="itemTaskType"
                    :label="$t('fullsearch.filter_TaskType')"
                    multiple
                    chips
                    class="ma-0 ml-md-4"
                  ></v-combobox>
                </v-col>

                <v-spacer></v-spacer>
                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectStatus"
                    :items="itemStatus"
                    :label="$t('fullsearch.filter_status')"
                    multiple
                    chips
                    class="ma-0 mr-md-4"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" md="5">
                  <v-combobox
                    clearable
                    prepend-icon="mdi-find-replace"
                    v-model="selectWhoResponsible"
                    :items="itemPersons"
                    :label="$t('fullsearch.filter_who_responsible')"
                    multiple
                    chips
                    class="ma-0 ml-md-4"
                  ></v-combobox>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>

              <v-row class="ma-0 ml-md-1">
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                    ref="menuDateBegin"
                    v-model="menuDateBegin"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateBegin"
                        :label="$t('fullsearch.date_begin')"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        @click:clear="dateBegin = null"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      v-model="dateBegin"
                      locale="sk-SK"
                      no-title
                      scrollable
                      @change="menuDateBegin = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-menu
                    ref="menuDateEnd"
                    v-model="menuDateEnd"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEnd"
                        :label="$t('fullsearch.date_end')"
                        prepend-icon="mdi-calendar"
                        clearable
                        @click:clear="dateEnd = null"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      v-model="dateEnd"
                      locale="sk-SK"
                      no-title
                      scrollable
                      @change="menuDateEnd = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-spacer></v-spacer>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-col cols="12" md="12">
          <span class="text-caption"
            >* fulltextove vyhladavanie zohladnuje:
          </span>
          <ul class="text-caption">
            <li>Tema stretnutia</li>
            <li>Agenda stretnutia</li>
            <li>Popis zaveru zo stretnutia</li>

            <li>Komentár k zaveru zo stretnutia</li>
          </ul>
        </v-col>

        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6" class="text-center mt-5">
            <download-excel
              class="btn"
              :fetch="downloadDataMeetings"
              :fields="json_fields_meetings"
              worksheet="My Worksheet"
              name="vybrate_stretnutia.xls"
            >
              <v-btn color="primary">
                {{ $t("fullsearch.download_concrete_meetings") }}
              </v-btn>
            </download-excel>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" md="6" class="text-center mt-md-5">
            <download-excel
              class="btn"
              :fetch="downloadDataTasks"
              :fields="json_fields_tasks"
              worksheet="My Worksheet"
              name="vybrate_zavery.xls"
            >
              <v-btn color="primary">
                {{ $t("fullsearch.download_concrete_tasks") }}
              </v-btn>
            </download-excel>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" md="6" class="text-center">
            <v-btn
              color="primary"
              dark
              class="ma-0 mr-md-0 mt-md-3"
              @click="newMeeting()"
            >
              {{ $t("fullsearch.new") }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6" class="text-center">
            <v-btn
              color="primary"
              dark
              class="ma-0 mr-md-5 mt-md-3"
              @click="resetFilter()"
            >
              {{ $t("fullsearch.reset") }}
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>

      <div class="px-5 py-10 grey--text text--darken-1 title">
        {{ $t("fullsearch.meeting_table") }}
      </div>

      <v-data-table
        :headers="headers_meetings"
        :items="items_meetings"
        item-key="id"
        class="elevation-1"
        @click:row="clickTableRowMeeting"
        :search="searchMeeting"
      >
        <template v-slot:[`item.agenda`]="{ value }">
          <div class="frame" style="height: 70px; overflow-y: scroll">
            <div :inner-html.prop="value | highlight(searchMeeting)"></div>
          </div>
        </template>
        <template v-slot:[`item.topic`]="{ value }">
          <div class="frame" style="height: 70px; overflow-y: scroll">
            <div :inner-html.prop="value | highlight(searchMeeting)"></div>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="editable(item.version.name, item.id)"
                small
                v-bind="attrs"
                v-on="on"
                fap
                class="pa-1 ma-0 mr-md-2"
                @click="editItemMeeting(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t("meeting_table.editTooltip") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="pa-1 ma-0 mr-md-2"
                v-show="editable(item.version.name, item.id)"
                small
                @click="deleteItemMeeting(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t("meeting_table.deletTooltip") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="pa-1"
                v-on="on"
                small
                @click="copyItemMeeting(item)"
              >
                mdi-content-paste
              </v-icon>
            </template>
            <span>{{ $t("meeting_table.copyTooltip") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <div class="px-5 py-10 grey--text text--darken-1 title">
        {{ $t("fullsearch.task_table") }}
      </div>

      <v-data-table
        :headers="headers_tasks"
        :items="items_tasks"
        item-key="id"
        :search="searchTask"
        @click:row="clickTableRowTask"
      >
        <template v-slot:[`item.name`]="{ value }">
          <div class="frame" style="height: 70px; overflow-y: scroll">
            <div :inner-html.prop="value | highlight(searchTask)"></div>
          </div>
        </template>
        <template v-slot:[`item.comment`]="{ value }">
          <div class="frame" style="height: 70px; overflow-y: scroll">
            <div :inner-html.prop="value | highlight(searchTask)"></div>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="editable(item.id)"
                small
                v-bind="attrs"
                v-on="on"
                fap
                class="pa-1 ma-0 mr-md-2"
                @click="editItemTask(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t("task_table.editTooltip") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>



<script>
import axios from "axios";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { mapGetters } from "vuex";
import { VueOfflineStorage } from "vue-offline";

Vue.component("downloadExcel", JsonExcel);
export default {
  data: () => ({
    id_token: "",
    json_fields_meetings: {
      ID: "uid",
      Projekt: "project.name",
      "Téma stretnutia": "topic",
      "Názov OVM": "ovm.name",
      "Dátum stretnutia": "date",
      Spracoval: "processedBy.name",
      Schvaľovateľ: "approvedBy.name",
      Verzia: "version.name",
      "Čas začiatku": "timeBegin",
      "Čas skončenia": "timeEnd",
      "Miesto konania": "place",
      Agenda: "agenda",
      Účastníci: {
        callback: (value) => {
          return value.persons
            .map(function (p) {
              return p.name;
            })
            .join(", ");
        },
      },
    },

    json_fields_tasks: {
      ID: "uid",
      "Typ záveru": "taskType.name",
      "Popis záveru": "name",
      "Názov stretnutia": "meetingTopic",
      Status: "status",
      "Názov OVM": "ovmName",
      Projekt: "project.name",
      Termín: "deadline",
      Zodpovedný: "responsible",
      Komentár: "comment",
    },

    admin: false,
    editMeetings: [],
    editTasks: [],
    dialog: false,
    isEditing: false,
    errorAxios: false,
    loading: true,
    menuDateBegin: false,
    menuDateEnd: false,
    dateBegin: null,
    dateEnd: null,
    itemOvm: [],
    itemOvmObj: [],
    itemProject: [],
    itemTaskType: [],
    itemPersons: [],
    itemVersion: [],
    itemStatusObj: [],
    itemStatus: [],
    itemVersionObj: [],
    itemProjectObj: [],
    itemTaskTypeObj: [],
    itemParticipant: [],
    itemWhoResponsible: [],
    itemWhoApproved: [],
    itemWhoProcessed: [],
    persons: [],
    selectParticipant: "",
    selectWhoApproved: "",
    selectWhoProcessed: "",
    selectProject: "",
    selectWhoResponsible: "",
    selectPerson: "",

    selectVersion: "",
    selectOvm: "",
    selectTaskType: "",
    selectStatus: "",
    dialogDelete: false,
    deletingFile: false,
    search: "",
    searchTask: "",
    searchMeeting: "",
    search_text: "",
    actualLoading: 0,
    trip: {
      name: "",
      location: null,
      start: null,
      end: null,
    },
    items_meetings: [],
    items_tasks: [],
    items: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      uid: "",
      topic: "",
      ovm: { name: "" },
      agenda: "",
      date: "",
      version: {
        id: 0,
        name: "",
      },
      persons: [],
    },

    headers_meetings: [
      { text: "ID", align: "start", value: "uid" },
      { text: "Téma stretnutia", align: "left", value: "topic" },
      { text: "OVM", value: "ovm.name" },
      { text: "Agenda", value: "agenda", sortable: false, width: "20%" },
      { text: "Dátum", value: "date" },
      { text: "Verzia", value: "version.name" },
      { text: "Akcie", value: "actions", sortable: false },
    ],
    headers_tasks: [
      { text: "ID", align: "start", value: "uid" },
      { text: "Typ", sortable: true, value: "taskType.name" },
      { text: "Popis", value: "name", width: "20%" },
      { text: "Komentár", value: "comment", sortable: false, width: "20%" },
      { text: "OVM", value: "ovmName" },
      { text: "Stav", value: "status" },
      { text: "Termín", value: "deadline" },
      { text: "Akcie", value: "actions", sortable: false },
    ],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken"]),
  },
  methods: {
    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response.status == 403) this.$router.push("login").catch(() => {});
      this.completeLoadingBlock();
    },
    saveOfflineMode(key, value) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        VueOfflineStorage.set(key, value);
      }
    },
    async loadData() {
      this.id_token = this.getToken;
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      // this.setLoadingBlock();

      // await this.loadToken();
      await this.loadEditingApproval();
      this.loadPersons();
      this.loadVersion();
      this.loadOvm();
      this.loadProject();
      this.loadTaskType();
      this.loadStatus();

      // await this.loadPersons();
      // await this.loadVersion();
      // await this.loadOvm();
      // await this.loadProject();
      // await this.loadTaskType();
      // await this.loadStatus();
    },
    newMeeting() {
      this.$router.push("create");
    },
    editable(name, id) {
      if (this.admin == true) return true;
      return (
        name != process.env.VUE_APP_NONE_EDITABLE_VERSION &&
        this.editMeetings.includes(id)
      );
    },
    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    loadEditingApproval() {
      return axios
        .get(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_GET_EDITING_APPROVAL,
          { headers: { Authorization: `Bearer ${this.id_token}` } }
        )
        .then((response) => {
          this.admin = response.data.admin;
          this.editMeetings = response.data.meetings;
          this.editTasks = response.data.tasks;
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    loadVersion() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_VERSION, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemVersionObj = response.data;

          this.itemVersion = [
            ...new Set(this.itemVersionObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("versions", response.data);
          this.itemVersion.sort();
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadOvm() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_OVM, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemOvmObj = response.data;

          this.itemOvm = [...new Set(this.itemOvmObj.map((item) => item.name))];
          this.saveOfflineMode("ovm", response.data);
          this.completeLoadingBlock();
          this.itemOvm.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadTaskType() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_TASKTYPE, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemTaskTypeObj = response.data;

          this.itemTaskType = [
            ...new Set(this.itemTaskTypeObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("taskTypes", response.data);
          this.completeLoadingBlock();
          this.itemTaskType.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadProject() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PROJECT, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemProjectObj = response.data;

          this.itemProject = [
            ...new Set(this.itemProjectObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("projects", response.data);
          this.completeLoadingBlock();
          this.itemProject.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadStatus() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_STATUS, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemStatusObj = response.data;

          this.itemStatus = [
            ...new Set(this.itemStatusObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("status", response.data);
          this.completeLoadingBlock();
          this.itemStatus.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadPersons() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.persons = response.data;
          this.itemPersons = this.persons
            .filter((p) => {
              return p.exist == 1;
            })
            .map(function (p) {
              return p.name + ", " + p.position + ", " + p.ovm.name;
            });
          this.itemPersons.sort();
          this.saveOfflineMode("persons", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    editItemTask(item) {
      this.$router.push("task/edit/" + item.id);
    },

    editItemMeeting(item) {
      this.$router.push("edit/" + item.id);
    },
    copyItemMeeting(item) {
      this.$router.push("copy/" + item.id);
    },
    deleteItemMeeting(item) {
      this.deletingFile = true;
      this.editedIndex = this.items_meetings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_GET_MEETING +
            "/" +
            this.meetings[this.editedIndex].id,
          { headers: { Authorization: `Bearer ${this.id_token}` } }
        )
        .then((response) => {
          this.items_meetings.splice(this.editedIndex, 1);
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clickTableRowTask(index) {
      this.$router.push("task/show/" + index.id);
    },

    clickTableRowMeeting(index) {
      if (this.deletingFile != true) {
        this.$router.push("show/" + index.id);
      }
      this.deletingFile = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    isFiltered() {
      if (
        this.dateEnd == null &&
        this.dateBegin == null &&
        this.selectWhoApproved == "" &&
        this.selectOvm == "" &&
        this.selectWhoProcessed == "" &&
        this.selectVersion == "" &&
        this.selectParticipant == "" &&
        this.selectTaskType == "" &&
        this.selectProject == "" &&
        this.selectStatus == "" &&
        this.selectWhoResponsible == ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    async actualSearch() {
      this.searchMeeting = this.search_text;
      this.searchTask = this.search_text;
      this.search = this.search_text;
      let idWhoApproved = null;
      if (this.selectWhoApproved)
        idWhoApproved = this.persons
          .filter((p) =>
            this.selectWhoApproved.includes(
              p.name + ", " + p.position + ", " + p.ovm.name
            )
          )
          .map(function (obj) {
            return obj.id;
          });

      let idWhoProcessed = null;
      if (this.selectWhoProcessed)
        idWhoProcessed = this.persons
          .filter((p) =>
            this.selectWhoProcessed.includes(
              p.name + ", " + p.position + ", " + p.ovm.name
            )
          )
          .map(function (obj) {
            return obj.id;
          });

      let idParticipant = null;
      if (this.selectParticipant)
        idParticipant = this.persons
          .filter((p) =>
            this.selectParticipant.includes(
              p.name + ", " + p.position + ", " + p.ovm.name
            )
          )
          .map(function (obj) {
            return obj.id;
          });

      let idWhoResponsible = null;
      if (this.selectWhoResponsible)
        idWhoResponsible = this.persons
          .filter((p) =>
            this.selectWhoResponsible.includes(
              p.name + ", " + p.position + ", " + p.ovm.name
            )
          )
          .map(function (obj) {
            return obj.id;
          });

      let idOvm = null;
      if (this.selectOvm)
        idOvm = this.itemOvmObj
          .filter((p) => this.selectOvm.includes(p.name))
          .map(function (obj) {
            return obj.id;
          });

      let idVersion = null;
      if (this.selectVersion)
        idVersion = this.itemVersionObj
          .filter((p) => this.selectVersion.includes(p.name))
          .map(function (obj) {
            return obj.id;
          });

      let idTaskType = null;
      if (this.selectTaskType)
        idTaskType = this.itemTaskTypeObj
          .filter((p) => this.selectTaskType.includes(p.name))
          .map(function (obj) {
            return obj.id;
          });

      let idProject = null;
      if (this.selectProject)
        idProject = this.itemProjectObj
          .filter((p) => this.selectProject.includes(p.name))
          .map(function (obj) {
            return obj.id;
          })[0];

      let idStatus = null;
      if (this.selectStatus)
        idStatus = this.itemStatusObj
          .filter((p) => this.selectStatus.includes(p.name))
          .map(function (obj) {
            return obj.id;
          });

      let idDateBegin = null;
      if (this.dateBegin)
        idDateBegin = this.dateBegin.substring(0, 10) + "T00:00:00.000+00:00";
      let idDateEnd = null;
      if (this.dateEnd)
        idDateEnd = this.dateEnd.substring(0, 10) + "T00:00:00.000+00:00";

      if (idOvm == [] || !idOvm || idOvm.length == 0) idOvm = null;
      if (idWhoProcessed == [] || !idWhoProcessed || idWhoProcessed.length == 0)
        idWhoProcessed = null;
      if (idWhoApproved == [] || !idWhoApproved || idWhoApproved.length == 0)
        idWhoApproved = null;
      if (idParticipant == [] || !idParticipant || idParticipant.length == 0)
        idParticipant = null;
      if (idProject == [] || !idProject || idProject.length == 0)
        idProject = null;
      if (idTaskType == [] || !idTaskType || idTaskType.length == 0)
        idTaskType = null;
      if (idVersion == [] || !idVersion || idVersion.length == 0)
        idVersion = null;
      if (idDateBegin == [] || !idDateBegin || idDateBegin.length == 0)
        idDateBegin = null;
      if (idDateEnd == [] || !idDateEnd || idDateEnd.length == 0)
        idDateEnd = null;
      if (idStatus == [] || !idStatus || idStatus.length == 0) idStatus = null;
      if (
        idWhoResponsible == [] ||
        !idWhoResponsible ||
        idWhoResponsible.length == 0
      )
        idWhoResponsible = null;

      let body = {
        ovm: idOvm,
        processedBy: idWhoProcessed,
        approvedBy: idWhoApproved,
        persons: idParticipant,
        project: idProject,
        taskType: idTaskType,
        version: idVersion,
        dateBegin: idDateBegin,
        dateEnd: idDateEnd,
        status: idStatus,
        responsibleBy: idWhoResponsible,
        search: this.search,
      };

      await this.searchMeetings(body);
      await this.searchTasks(body);
    },

    searchMeetings(body) {
      var optionAxios = {
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
          Authorization: `Bearer ${this.id_token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf8",
        },
      };
      this.setLoadingBlock();
      axios
        .post(
          process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_MEETING_FILTER,
          body,
          optionAxios
        )
        .then((response) => {
          this.items_meetings = response.data;
          this.items_meetings.forEach(
            (element) =>
              (element.participants = [
                ...new Set(
                  element.persons.map(
                    (item) =>
                      item.name + ", " + item.position + ", " + item.ovm.name
                  )
                ),
              ].join(", "))
          );

          this.items_meetings.forEach(
            (element) => (element.uid = element.project.acronym + element.id)
          );
          this.items_meetings.map((item) => {
            item.date = item.date.substring(0, 10);
          });

          this.completeLoadingBlock();
          this.dialogSave = true;
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    searchTasks(body) {
      var optionAxios = {
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
          Authorization: `Bearer ${this.id_token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf8",
        },
      };
      this.setLoadingBlock();
      axios
        .post(
          process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_TASK_FILTER,
          body,
          optionAxios
        )
        .then((response) => {
          this.items_tasks = response.data;
          this.items_tasks.forEach(
            (element) =>
              (element.uid =
                element.project.acronym + element.meetingId + "." + element.uid)
          );
          this.items_tasks.map((item) => {
            if (item.deadline) {
              item.deadline = item.deadline.substring(0, 10);
            }
          });
          this.items_tasks.map((item) => {
            if (item.status) {
              item.status = item.status.name;
            }
          });
          this.items_tasks.map((item) => {
            item.responsible = "";
            if (item.person) {
              item.responsible =
                item.person.name +
                ", " +
                item.person.position +
                ", " +
                item.person.ovm.name;
            }
          });

          this.completeLoadingBlock();
          this.dialogSave = true;
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    resetFilter() {
      this.dateEnd = null;
      this.dateBegin = null;
      this.search = "";
      this.searchTask = "";
      this.searchMeeting = "";
      this.selectWhoApproved = "";
      this.selectOvm = "";
      this.selectWhoProcessed = "";
      this.selectVersion = "";
      this.selectParticipant = "";
      this.selectTaskType = "";
      this.selectProject = "";
      this.selectStatus = "";
      this.selectWhoResponsible = "";
    },

    async downloadDataTasks() {
      return this.items_tasks;
    },
    async downloadDataMeetings() {
      return this.items_meetings;
    },
  },

  filters: {
    highlight: function (value, query) {
      if (value)
        return value.replace(
          new RegExp(query, "ig"),
          "<span class='filter_search'>" + query + "</span>"
        );
      else return "-";
    },
    highlightRow: function (row, query) {
      let ret = "";
      Object.keys(row).forEach((column) => {
        if (typeof row[column] === "string") {
          ret =
            ret +
            row[column].replace(
              new RegExp(query, "ig"),
              "<span class='blue'>" + query + "</span>"
            );
        }
      });
      return ret;
    },
  },
};
</script>


<style scoped>
.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>